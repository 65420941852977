<template>
    <common-form
            ref="form"
            url="appUser"
            :items="items"
            :edit="edit"
            @success="$emit('success')">
    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit:false,
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "input",
                        name: "姓名",
                        key: "nickName",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "手机号",
                        key: "phone",
                        required: true,
                    },
                    {
                        type: "select",
                        name: "类型",
                        key: "type",
                        required: true,
                        options:[
                            { name:"普通用户", value:1 },
                            { name:"注册用户", value:2 },
                            { name:"省级客户", value:3 },
                            { name:"市级客户", value:4 },
                            { name:"网点客户", value:5 },
                            { name:"零售客户", value:6 },

                        ]
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.identityId > 0
                this.$refs.form.show(model)
            },

        }
    }
</script>
